<template>
  <div class="card mb-3">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center" id="content">{{ titulo }}</div>
      </div>
      <div class="row">
        <slot name="indicativos"></slot>
      </div>
      <div class="row">
        <slot name="pesquisa"></slot>
      </div>
      <div class="row justify-content-end">
          <slot name="btnAdd"></slot>
      </div>
      <slot name="emailCipa"></slot>
      <p class="m-0 p-0 form-text text-muted" v-if="data_limite">
        Expira em: {{ data_limite | formataDataGlobal }}
      </p>
    </div>
    <div class="card-body p-0">
      <slot name="conteudo"></slot>
    </div>
    <div class="card-footer">
      <slot name="rodape"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["titulo", "data_limite"],
  mounted() {
    $(".open-popup-link").magnificPopup({
      delegate: "a",
      type: "image",
    });
    function linkify(text) {
    // Regex para capturar URLs que ainda não estão em links
    const urlPattern = /(?<!href=["'])(https?:\/\/[^\s]+)/g

    // Substituir URLs por links clicáveis
    return text.replace(urlPattern, "<a href='$1' target='_blank' rel='noopener noreferrer'>⏩</a>");
    }
// Obter o conteúdo da div e transformar URLs em links
const contentDiv = document.getElementById('content');
contentDiv.innerHTML = linkify(contentDiv.innerHTML);
  },
};
</script>
