<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-10 mb-2">
        <card-component titulo="Vale Cesta">
          <template v-slot:indicativos>
            <div>
              <p><b class="float-end p-1">Total: {{ (totalPermissaoCestaAtivos + totalPermissaoCestaInativos) }}</b></p>
              <p><b class="float-end p-1">Ativos: {{ totalPermissaoCestaAtivos }}</b></p>
              <p><b class="float-end p-1">Inativos: {{ totalPermissaoCestaInativos }}</b></p>
            </div>
          </template>
          <template v-slot:conteudo>
            <div class="p-1" style="font-weight: 600">
              <div class="row">
                <div class="col" style="border-right: solid;">
                  <div class="row">
                    <p class="col">
                      Data Inicial:
                      {{ dataCestaCarregada.data_inicio | formataDataGlobal }}
                    </p>
                    <p class="col">
                      Data Final:
                      {{ dataCestaCarregada.data_fim | formataDataGlobal }}
                    </p>
                  </div>
                  <button type="submit" class="btn btn-primary btn-sm m-2" data-bs-toggle="modal"
                    data-bs-target="#modalCestaData" @click="resetModal()">
                    Data Cesta
                  </button>
                </div>
                <div class="col">
                  <div class="row">
                    <p class="col">
                      Data Mudança Inicial:
                      {{ dataCestaMudancaCarregada.data_inicio | formataDataGlobal }}
                    </p>
                    <p class="col">
                      Data Mudança Final:
                      {{ dataCestaMudancaCarregada.data_fim | formataDataGlobal }}
                    </p>
                  </div>
                  <button type="submit" class="btn btn-primary btn-sm m-2" data-bs-toggle="modal"
                    data-bs-target="#modalCestaDataMudanca" @click="resetModal()">
                    Mudança Tipo Cesta
                  </button>
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary btn-sm float-end m-2" data-bs-toggle="modal"
              data-bs-target="#modalPermissaoCesta" @click="resetModal()">
              Atualizar permissao vale cesta
            </button>
          </template>
        </card-component>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-10">
        <card-component titulo="Vale Cesta Colaboradores">
          <template v-slot:pesquisa>
            <div class="row">
              <div class="col mb-3">
                <input-container-component titulo="Buscar por registro" id="inputRegistro" id-help="registroHelp">
                  <input type="number" class="form-control" id="inputRegistro" aria-describedby="registroHelp"
                    placeholder="Registro" v-model="busca.registro" @keyup="pesquisar()" />
                </input-container-component>
              </div>
              <div class="col mb-3">
                <input-container-component titulo="Buscar por nome" id="inputNome" id-help="nomeHelp">
                  <input type="text" class="form-control" id="inputNome" aria-describedby="nomeHelp" placeholder="Nome"
                    v-model="busca.name" @keyup="pesquisar()" />
                </input-container-component>
              </div>
            </div>
          </template>
          <template v-slot:btnAdd>
	    <div style="display: contents;">
		<a href="https://portalcolaborador.candido.org.br/users-export" style="color:blue; margin-right: 5px;">Exportar Tipo Cesta</a>
	        <button type="submit" class="btn btn-primary btn-sm btnAdd" data-bs-toggle="modal"
	              data-bs-target="#modalUsuariosAdicionar">
        	      Cadastrar Usuario
	        </button>
          </template>
          <template v-slot:conteudo>
            <table-component :dados="usuarios.data" :btnAtualizar="{
              visivel: true,
              dataToggle: 'modal',
              dataTarget: '#modalUsuariosAtualizar',
            }" :btnRemover="{
              visivel: true,
              dataToggle: 'modal',
              dataTarget: '#modalUsuariosRemover',
            }" :titulos="{
              id: { titulo: '#', tipo: 'texto' },
              imagem: { titulo: 'Perfil', tipo: 'imagem' },
              registro: { titulo: 'Registro', tipo: 'texto' },
              name: { titulo: 'Nome', tipo: 'texto' },
              tipo_cesta: { titulo: 'Vale-Cesta', tipo: 'texto' },
              permissao_cesta: { titulo: 'Cesta-Premium', tipo: 'texto' },
              nascimento: { titulo: 'Nascimento', tipo: 'date' },
            }"></table-component>
          </template>
          <template v-slot:rodape>
            <div class="row">
              <div class="col-12">
                <paginate-component>
                  <li v-for="(l, key) in usuarios.links" :key="key" :class="l.active ? 'page-item active' : 'page-item'"
                    @click="paginacao(l)">
                    <a class="page-link" v-html="l.label"></a>
                  </li>
                </paginate-component>
              </div>
            </div>
          </template>
        </card-component>
      </div>
    </div>
    <modal-component id="modalUsuariosAdicionar" titulo="Cadastrar usuário">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <input-container-component titulo="Registro">
          <input type="number" class="form-control" v-model="usuario.registro" />
        </input-container-component>
        <input-container-component titulo="Nome">
          <input type="text" class="form-control" v-model="usuario.name" />
        </input-container-component>
        <input-container-component titulo="Data de nascimento">
          <input type="date" class="form-control" name="nascimento" id="inputEncerramento"
            aria-describedby="EncerramentoHelp" :max="dtMax" v-model="usuario.nascimento" />
        </input-container-component>
        <input-container-component titulo="Vale-Cesta">
          <select name="tipo_cesta" class="form-control" id="tipo_cesta" v-model="usuario.tipo_cesta">
            <option value="A">A</option>
            <option value="B">B</option>
          </select>
        </input-container-component>
        <input-container-component titulo="Cesta-Premium">
          <select name="permissao_cesta" class="form-control" id="permissao_cesta" v-model="usuario.permissao_cesta">
            <option value="Inativo">Inativo</option>
            <option value="Ativo">Ativo</option>
          </select>
        </input-container-component>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="adicionar()">Salvar</button>
      </template>
    </modal-component>
    <modal-component id="modalUsuariosAtualizar" titulo="Atualizar usuário">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <input-container-component titulo="">
          <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="resetarSenha()">
            Resetar senha
          </button>
          <div class="position-relative">
            <img v-if="$store.state.item.imagem != null" :src="'/storage/' + $store.state.item.imagem" width="140"
              height="140" style="border-radius: 50%; object-fit: cover" class="mx-auto d-block" />
            <img v-else src="perfil_padrao.jpg" width="140" height="140" style="border-radius: 50%; object-fit: cover"
              class="mx-auto d-block" />
          </div>
        </input-container-component>
        <input-container-component titulo="Registro">
          <input type="number" class="form-control" v-model="$store.state.item.registro" disabled />
        </input-container-component>
        <input-container-component titulo="Nome">
          <input type="text" class="form-control" v-model="$store.state.item.name" />
        </input-container-component>
        <input-container-component titulo="Data de nascimento">
          <input type="date" class="form-control" name="nascimento" id="inputEncerramento"
            aria-describedby="EncerramentoHelp" :max="dtMax" v-model="$store.state.item.nascimento" />
        </input-container-component>
        <input-container-component titulo="Vale-Cesta">
          <select name="tipo_cesta" class="form-control" id="tipo_cesta" v-model="$store.state.item.tipo_cesta">
            <option value="A">A</option>
            <option value="B">B</option>
          </select>
        </input-container-component>
        <input-container-component titulo="Cesta-Premium">
          <select name="permissao_cesta" class="form-control" id="permissao_cesta"
            v-model="$store.state.item.permissao_cesta">
            <option value="Inativo">Inativo</option>
            <option value="Ativo">Ativo</option>
          </select>
        </input-container-component>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizar()">Salvar</button>
      </template>
    </modal-component>
    <modal-component id="modalUsuariosRemover" titulo="Remover usuário">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo> 
        <input-container-component titulo="">
          <div class="position-relative">
            <img v-if="$store.state.item.imagem != null" :src="'/storage/' + $store.state.item.imagem" width="140"
              height="140" style="border-radius: 50%; object-fit: cover" class="mx-auto d-block" />
            <img v-else src="perfil_padrao.jpg" width="140" height="140" style="border-radius: 50%; object-fit: cover"
              class="mx-auto d-block" />
          </div>
        </input-container-component>
        <input-container-component titulo="Registro">
          <input type="number" class="form-control" :value="$store.state.item.registro" disabled />
        </input-container-component>
        <input-container-component titulo="Nome">
          <input type="text" class="form-control" :value="$store.state.item.name" disabled />
        </input-container-component>
        <input-container-component titulo="Vale-Cesta">
          <select name="tipo_cesta" class="form-control" disabled>
            <option value="0" :selected="$store.state.item.tipo_cesta == 'A'">
              A
            </option>
            <option value="1" :selected="$store.state.item.tipo_cesta == 'B'">
              B
            </option>
          </select>
        </input-container-component>
        <input-container-component titulo="Cesta-Premium">
          <select name="permissao_cesta" class="form-control" disabled>
            <option value="0" :selected="$store.state.item.permissao_cesta == 'Inativo'">
              Inativo
            </option>
            <option value="1" :selected="$store.state.item.permissao_cesta == 'Ativo'">
              Ativo
            </option>
          </select>
        </input-container-component>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-danger" @click="remover()"
          v-once="$store.state.transacao.status != 'sucesso'">
          Remover
        </button>
      </template>
    </modal-component>
    <modal-component id="modalCestaData" titulo="Data cesta">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <input-container-component titulo="Data inicial" id="inputDataInicial" id-help="dataInicialHelp"
            class="col-6">
            <input type="date" class="form-control" id="inputDataInicial" aria-describedby="dataInicialHelp"
              v-model="dataCestaCarregada.data_inicio" />
          </input-container-component>
          <input-container-component titulo="Data final" id="inputDataFinal" id-help="dataFinalHelp" class="col-6">
            <input type="date" class="form-control" id="inputDataFinal" aria-describedby="dataFinalHelp"
              v-model="dataCestaCarregada.data_fim" />
          </input-container-component>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizarDataCesta()">
          Salvar
        </button>
      </template>
    </modal-component>
    <modal-component id="modalCestaDataMudanca" titulo="Mudança Data cesta">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <input-container-component titulo="Data inicial" id="inputMudancaDataInicial" id-help="dataInicialHelp"
            class="col-6">
            <input type="date" class="form-control" id="inputMudancaDataInicial" aria-describedby="dataInicialHelp"
              v-model="dataCestaMudancaCarregada.data_inicio" />
          </input-container-component>
          <input-container-component titulo="Data final" id="inputMudancaDataFinal" id-help="dataFinalHelp" class="col-6">
            <input type="date" class="form-control" id="inputMudancaDataFinal" aria-describedby="dataFinalHelp"
              v-model="dataCestaMudancaCarregada.data_fim" />
          </input-container-component>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizarDataCestaMudanca()">
          Salvar
        </button>
      </template>
    </modal-component>
    <modal-component id="modalPermissaoCesta" titulo="Data cesta">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row justify-content-center">
          <input-container-component titulo="Lista de registros" id="inputPermissaoCesta" id-help="permissaoCestaHelp"
            class="col-6">
            <textarea name="inputPermissaoCesta" id="inputPermissaoCesta" cols="20" rows="8"
              aria-describedby="permissaoCestaHelp" class="form-control" v-model="registrosUpdateCesta"></textarea>
          </input-container-component>
          <input-container-component titulo="Vale-Cesta">
            <select name="permissao_cesta" class="form-control w-50" id="permissao_cesta"
              v-model="updatePermissaoCesta">
              <option value="" disabled selected>Selecione uma opção</option>
              <option value="0">Inativo</option>
              <option value="1">Ativo</option>
            </select>
          </input-container-component>
        </div>
        <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="inativarTodos()">
          Inativar Todos
        </button>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizarPermissaoCesta()">
          Salvar
        </button>
      </template>
    </modal-component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/usuarios",
      urlPaginacao: "",
      urlFiltro: "",
      usuarios: { data: [] },
      usuario: {
        registro: "",
        name: "",
        nascimento: "",
        permissao_cesta: "",
        tipo_cesta: ""
      },
      titulos: [],
      busca: { registro: "", name: "" },
      dtMax: "",
      updatePermissaoCesta: "",
      registrosUpdateCesta: "",
      dataCestaCarregada: "",
      dataCestaMudancaCarregada: "",
      totalPermissaoCestaAtivos: "",
      totalPermissaoCestaInativos: "",
    };
  },
  methods: {
    resetarSenha() {
      let confirmacao = confirm("Resetar senha? Nova senha: senha123");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("password", "senha123");
      formData.append("password_confirmation", "senha123");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    atualizarDataCesta() {
      let url = "https://portalcolaborador.candido.org.br/api/v1/cesta/1";
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("data_inicio", this.dataCestaCarregada.data_inicio);
      formData.append("data_fim", this.dataCestaCarregada.data_fim);
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregaDataCesta();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    atualizarDataCestaMudanca() {
      let url = "https://portalcolaborador.candido.org.br/api/v1/mudanca_cesta/1";
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("data_inicio", this.dataCestaMudancaCarregada.data_inicio);
      formData.append("data_fim", this.dataCestaMudancaCarregada.data_fim);
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregaDataCesta();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    inativarTodos() {
      let confirmacao = confirm("Deseja inativar todos os registros?");
      if (!confirmacao) return false;
      let url = "https://portalcolaborador.candido.org.br/api/v1/inativar_cesta";
      let formData = new FormData();
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    atualizarPermissaoCesta() {
      let url = "https://portalcolaborador.candido.org.br/api/v1/permissao_cesta";
      let formData = new FormData();
      this.registrosUpdateCesta = this.registrosUpdateCesta.split("\n");
      formData.append("permissao_cesta", this.updatePermissaoCesta);
      formData.append("registros", this.registrosUpdateCesta);
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
          this.registrosUpdateCesta = "";
          this.updatePermissaoCesta = "";
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
          this.registrosUpdateCesta = "";
          this.updatePermissaoCesta = "";
        });
    },
    resetModal() {
      this.usuario.registro = "";
      this.usuario.name = "";
      this.usuario.nascimento = "";
      this.usuario.tipo_cesta = "";
      this.usuario.permissao_cesta = "";
    },
    calcDataMinNascimento() {
      this.dtMax =
        new Date().getFullYear() -
        16 +
        "-" +
        (new Date().getMonth() + 1).toString().padStart(2, "0") +
        "-" +
        new Date().getDate().toString().padStart(2, "0");
    },
    adicionar() {
      let formData = new FormData();
      formData.append("registro", this.usuario.registro);
      formData.append("name", this.usuario.name);
      formData.append("nascimento", this.usuario.nascimento);
      formData.append(
        "tipo_cesta",
        this.usuario.tipo_cesta == "A" ? 0 : 1
      );
      formData.append(
        "permissao_cesta",
        this.usuario.permissao_cesta == "Inativo" ? 0 : 1
      );
      formData.append("password", "senha123");
      formData.append("password_confirmation", "senha123");
      formData.append("permissao_sistema", "user");

      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
          this.resetModal();
        })
        .catch((errors) => {
          this.setMessage("erro", errors.response.data.errors);
        });
    },
    atualizar() {
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("registro", this.$store.state.item.registro);
      formData.append("name", this.$store.state.item.name);
      formData.append("nascimento", this.$store.state.item.nascimento);
      formData.append(
        "tipo_cesta",
        this.$store.state.item.tipo_cesta == "A" ? 0 : 1
      );
      formData.append(
        "permissao_cesta",
        this.$store.state.item.permissao_cesta == "Inativo" ? 0 : 1
      );

      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarDados();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    pesquisar() {
      let filtro = "";
      for (let chave in this.busca) {
        if (this.busca[chave]) {
          if (filtro != "") {
            filtro += ";";
          }
          filtro += chave + ":like:" + this.busca[chave] + "%";
        }
      }
      if (filtro != "") {
        this.urlPaginacao = "&page=1";
        this.urlFiltro = "&filtro=" + filtro;
      } else {
        this.urlFiltro = "";
      }
      this.carregarDados();
    },
    paginacao(l) {
      if (l.url) {
        this.urlPaginacao = "&" + l.url.split("?")[1];
        this.carregarDados();
      }
    },
    carregarDados() {
      this.calcAtivosInativos();
      let url = this.urlBase + "?paginacao=20" + this.urlPaginacao + this.urlFiltro;
      axios
        .get(url)
        .then((response) => {
          this.usuarios = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregaDataCesta() {
      axios
        .get("https://portalcolaborador.candido.org.br/api/v1/cesta")
        .then((response) => {
          this.dataCestaCarregada = response.data[0];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregaDataCestaMudanca() {
      axios
        .get("https://portalcolaborador.candido.org.br/api/v1/mudanca_cesta")
        .then((response) => {
          this.dataCestaMudancaCarregada = response.data[0];
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    calcAtivosInativos() {
      let url = this.urlBase;
      axios
        .get(url)
        .then((response) => {
          this.totalPermissaoCestaAtivos = response.data.filter(
            (x) => x.permissao_cesta == 1
          ).length;
          this.totalPermissaoCestaInativos = response.data.filter(
            (x) => x.permissao_cesta == 0
          ).length;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
  },
  mounted() {
    this.carregaDataCesta();
    this.carregaDataCestaMudanca();
    this.carregarDados();
    this.calcDataMinNascimento();
  },
};
</script>
<style>
.btnAdd {
  width: 20%;
}

@media screen and (max-width: 450px) {
  .btnAdd {
    width: 50%;
  }
}
</style>
