<template>
  <div class="container">
    <div class="row justify-content-center w-100" style="padding: 0; margin: 0 auto">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header" style="text-align: center">
            <b>Bem-vindo(a) {{ userJson.name }}</b>
          </div>

          <div class="card-body">
            <!-- <div class="row"> -->
            <btn-cesta-component :nome="userJson.name" :datacesta="dataCesta" :permissaocesta="userJson.permissao_cesta"
              :registroUser="userJson.registro"></btn-cesta-component>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center w-100 mt-2" style="padding: 0; margin: 0 auto" v-if="periodoCestaMud && userJson.mudanca_cesta == 'sim'">
      <mudanca-cesta-component :tipocesta="userJson.tipo_cesta" :iduser="userJson.id"></mudanca-cesta-component>
    </div>
    <button type="submit" class="btn btn-primary btn-sm float-end m-3" data-bs-toggle="modal"
      data-bs-target="#modalPrincipalAdicionar" @click="resetModal()"
      v-if="userJson.permissao_sistema == 'adm' || userJson.permissao_sistema == 'marketing'"
      style="margin-right: 20px">
      Adicionar
    </button>
    <div class="row justify-content-center container-responsive">
      <card-component v-for="p in principal" :key="p.id" class="w-300px" :titulo="p.texto">
        <template v-slot:conteudo>
          <div class="open-popup-link" v-if="p.imagem != ''">
            <a :href="'/storage/' + p.imagem" class="img">
              <img :src="'/storage/' + p.imagem" :alt="'Descrição ' + p.texto" width="180px" height="315px" />
            </a>
          </div>
        </template>
        <template v-slot:rodape v-if="userJson.permissao_sistema == 'adm' || userJson.permissao_sistema == 'marketing'">
          <button type="submit" class="btn btn-primary btn-sm float-end" data-bs-toggle="modal"
            data-bs-target="#modalPrincipalEdit" @click="setStore(p)">
            Editar
          </button>
          <button type="submit" class="btn btn-danger btn-sm float-end" style="margin-right: 10px"
            data-bs-toggle="modal" data-bs-target="#modalPrincipalRemove" @click="setStore(p)">
            Excluir
          </button>
        </template>
      </card-component>
    </div>
    <div style="width: 100%; display: flex; justify-content: center; margin-top: 20px;">
      <!-- <a href="https://portalcolaborador.candido.org.br/noticias" class="btn btn-primary btn-sm mt-3 p-2"
        >Veja mais</a> -->
    </div>

    <!-- ########################################## --> 
    <!-- Inicio modal adicionar vagas -->
    <modal-component id="modalPrincipalAdicionar" titulo="Adicionar noticia">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Texto da postagem" id="inputTexto" id-help="textoHelp"
                texto-ajuda="Informe texto da postagem">
                <hr />
                <textarea name="texto" id="inputTexto" cols="20" rows="8" aria-describedby="textoHelp"
                  class="form-control" v-model="texto"></textarea>
                <!-- <input type="text" class="form-control" name="texto" id="inputTexto" aria-describedby="textoHelp"
                  placeholder="Informe texto da postagem" v-model="texto" /> -->
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img v-if="urlPreview" :src="urlPreview" width="150" height="200" />
              </div>
              <input-container-component titulo="Selecione a imagem" id="inputImagem" id-help="imagemHelp"
                texto-ajuda="Selecione a imagem">
                <hr />
                <input type="file" name="imagem" class="form-control" id="inputImagem" aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem" @change="carregarImagem($event)" />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="salvar()">Salvar</button>
      </template>
    </modal-component>
    <!-- Fim modal adiocionar notiicia -->
    <!-- ########################################## -->
    <!-- Inicio modal remover notiicia -->
    <modal-component id="modalPrincipalRemove" titulo="Remover noticia">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na Transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Texto da postagem" id="inputTexto" id-help="textoHelp"
                texto-ajuda="Texto da postagem">
                <hr />
                <textarea name="texto" id="inputTexto" cols="20" rows="8" aria-describedby="textoHelp"
                  class="form-control" v-model="$store.state.item.texto"></textarea>
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <img :src="'/storage/' + $store.state.item.imagem" width="200" height="400"
                v-if="$store.state.item.imagem" />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-danger" @click="remover()"
          v-if="$store.state.transacao.status != 'sucesso'">
          Remover
        </button>
      </template>
    </modal-component>
    <!-- ########################################## -->
    <!-- Inicio modal atualizar vagas -->
    <modal-component id="modalPrincipalEdit" titulo="Atualizar noticia" v-if="$store.state.item">
      <template v-slot:alertas>
        <alert-component mensagem="Transação realizada com sucesso" tipo="success" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
        <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
          v-if="$store.state.transacao.status == 'erro'"></alert-component>
      </template>
      <template v-slot:conteudo>
        <div class="row">
          <div class="col mb-3" style="">
            <div class="form-group">
              <input-container-component titulo="Texto da postagem" id="inputTexto" id-help="textoHelp"
                texto-ajuda="Texto da postagem">
                <hr />
                <textarea name="texto" id="inputTexto" cols="20" rows="8" aria-describedby="textoHelp"
                  class="form-control" v-model="$store.state.item.texto"></textarea>
              </input-container-component>
            </div>
          </div>
          <div class="col mb-3">
            <div class="form-group">
              <div id="preview">
                <img v-if="urlPreview" :src="urlPreview" width="150" height="200" />
                <img v-if="$store.state.item.imagem != null && urlPreview == ''"
                  :src="'/storage/' + $store.state.item.imagem" width="150" height="200" />
              </div>
              <input-container-component titulo="Selecione a imagem" id="inputImagem" id-help="imagemHelp"
                texto-ajuda="Selecione a imagem">
                <hr />
                <input type="file" name="imagem" class="form-control" id="inputImagem" aria-describedby="imagemHelp"
                  placeholder="Selecione uma imagem" @change="carregarImagem($event)" />
              </input-container-component>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rodape>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Fechar
        </button>
        <button type="button" class="btn btn-primary" @click="atualizar()">
          Atualizar
        </button>
      </template>
    </modal-component>
    <!-- Fim modal atualizar vagas -->
    <!-- ########################################## -->
    <!-- <div class="modal" id="NLogo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
      data-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"></h5>
          </div>
          <div class="modal-body">
            <h5 class="modal-title mb-4" id="exampleModalLabel"></h5>
            <video controls="controls" autoplay class="card-img-top" id="video" webkit-playsinline="false"
              playsinline="false">
              <source src="nova_marca.mp4" type="video/mp4" />
            </video>
            <hr />
            <center>
              <h5 style="color: #77c597; margin-top: 10px">
                Cândido Ferreira 100 anos!
              </h5>
            </center>
          </div>
          <div class="modal-footer d-flex flex-col bd-highlight">
            <div class="btn-fechar">
              <button type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: ["user"],
  data() {
    return {
      urlBase: "https://portalcolaborador.candido.org.br/api/v1/principal",
      imagem: [],
      transacaoStatus: "",
      transacaoDetalhes: {},
      principal: [],
      texto: "",
      urlPreview: "",
      dataCesta: "",
      tipo_cesta: "",
      periodoCestaMud: "",
      dataCestaMudancaCarregada: "",
      deferredPrompt: null
    };
  },
  methods: {
    resetModal() {
      this.$store.state.item = {};
      this.texto = "";
      this.imagem = [];
      this.urlPreview = "";
      inputImagem[0].value = "";
    },
    atualizar() {
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("texto", this.$store.state.item.texto);
      if (this.imagem[0] != null && this.imagem[0] != undefined) {
        formData.append("imagem", this.imagem[0]);
      }

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      let url = this.urlBase + "/" + this.$store.state.item.id;

      axios
        .post(url, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarPrincipal();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    remover() {
      let confirmacao = confirm("Confirmar remoção do registro?");
      if (!confirmacao) return false;
      let url = this.urlBase + "/" + this.$store.state.item.id;
      let formData = new FormData();
      formData.append("_method", "delete");
      let config = {
        headers: {
          Accept: "application/json",
          Authorization: this.token,
        },
      };
      axios
        .post(url, formData, config)
        .then((response) => {
          this.carregarPrincipal();
          this.setMessage("sucesso", response.data.msg);
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    setStore(obj) {
      this.resetModal();
      this.$store.state.item = obj;
    },
    carregarPrincipal() {
      axios
        .get(this.urlBase)
        .then((response) => {
          this.principal = response.data;
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    carregarImagem(e) {
      this.imagem = e.target.files;
      this.urlPreview = URL.createObjectURL(this.imagem[0]);
    },
    salvar() {
      let formData = new FormData();
      formData.append("texto", this.texto);
      formData.append("imagem", this.imagem[0]);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      axios
        .post(this.urlBase, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarPrincipal();
          this.resetModal();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.errors,
            errors.response.data.message
          );
        });
    },
    getDataCesta() {
      axios.get("https://portalcolaborador.candido.org.br/api/v1/cesta").then((response) => {
        this.dataCesta = response.data[0];
        this.dataCesta.data_inicio = response.data[0].data_inicio.split(" ")[0];
        this.dataCesta.data_fim = response.data[0].data_fim.split(" ")[0];
      });
    },
    carregaDataCestaMudanca() {
      axios
        .get("https://portalcolaborador.candido.org.br/api/v1/mudanca_cesta")
        .then((response) => {
          this.dataCestaMudancaCarregada = response.data[0];
          this.dataCestaMudancaCarregada.data_inicio = response.data[0].data_inicio.split(" ")[0];
          this.dataCestaMudancaCarregada.data_fim = response.data[0].data_fim.split(" ")[0];
          if (Date.parse(this.dataCestaMudancaCarregada.data_inicio) >= Date.parse(new Date()) && Date.parse(this.dataCestaMudancaCarregada.data_fim) > new Date().setDate(new Date().getDate() - 1)) {
            this.periodoCestaMud = false
          } else {
            this.periodoCestaMud = true
          }
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
     installPWA() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("Usuário aceitou a instalação do PWA");
          } else {
            console.log("Usuário recusou a instalação do PWA");
          }
          this.deferredPrompt = null;
        });
      }
    }
  },
  mounted() {
    this.carregarPrincipal();
    this.getDataCesta();
    this.carregaDataCestaMudanca();
    // $("body").click(() => { 
    //   if (!document.querySelector("#NLogo").classList.contains("show")) {
    //     let video = document.getElementById("video");
    //     video.pause();
    //   }
    // });

    // $("#video").contextmenu((e) => {
    //   e.preventDefault();
    // });

    // $(document).ready(function () {
    //   $("#NLogo").modal("show");
    // });
    // Verifica se o usuário está em um dispositivo móvel
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    // Verifica se o site está rodando dentro do PWA
    const isStandalone = window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone;

    // Captura o evento de instalação do PWA
    window.addEventListener("beforeinstallprompt", (event) => {
      if (isMobile && !isStandalone) {
        event.preventDefault();
        this.deferredPrompt = event;
        
        // Exibe um alerta informando sobre a instalação
        const userConfirmed = window.confirm("Deseja instalar o aplicativo?");
        if (userConfirmed) {
          this.installPWA();
        }
      }
    });
  },
  computed: {
    userJson() {
      return JSON.parse(this.user);
    },
  },
};
</script>
