/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        item: {},
        transacao: { status: '', mensagem: '', dados: '' }
    }
})

const MyPlugin = {
    install(Vue, options) {
        Vue.prototype.resetMessage = () => {
            setTimeout(() => {
                store.state.transacao.status = ""
                store.state.transacao.mensagem = ""
                store.state.transacao.dados = {}
            }, 3000)
        }
        Vue.prototype.setMessage = (status, mensagem = null, dados = null) => {
            store.state.transacao.mensagem = mensagem
            store.state.transacao.dados = dados;
            store.state.transacao.status = status
            setTimeout(() => {
                store.state.transacao.status = ""
                store.state.transacao.mensagem = ""
                store.state.transacao.dados = {}
            }, 3000)
        }
    }
}

Vue.use(MyPlugin)
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
Vue.component('login-component', require('./components/Login.vue').default);
Vue.component('me-component', require('./components/Me.vue').default);
Vue.component('principal-component', require('./components/Principal.vue').default);
Vue.component('noticias-component', require('./components/Noticias.vue').default);
Vue.component('vagas-interna-component', require('./components/VagasInterna.vue').default);
Vue.component('aniverssariantes-component', require('./components/Aniverssariantes.vue').default);
Vue.component('relogio-ponto-component', require('./components/RelogioPonto.vue').default);
Vue.component('gestao-cesta-component', require('./components/Cesta.vue').default);
Vue.component('input-container-component', require('./components/InputContainer.vue').default);
Vue.component('table-component', require('./components/Table.vue').default);
Vue.component('card-component', require('./components/Card.vue').default);
Vue.component('modal-component', require('./components/Modal.vue').default);
Vue.component('alert-component', require('./components/Alert.vue').default);
Vue.component('paginate-component', require('./components/Paginate.vue').default);
Vue.component('cesta-basica-component', require('./components/CestaBasica.vue').default);
Vue.component('cesta-premium-component', require('./components/CestaPremium.vue').default);
Vue.component('cipa-component', require('./components/Cipa.vue').default);
Vue.component('btn-cesta-component', require('./components/BtnCesta.vue').default);
Vue.component('mudanca-cesta-component', require('./components/MudancaTipoCesta.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.filter('formataDataGlobal', (d) => {
    if (!d) return ''
    d = d.split(' ')[0]
    let data = d.split('-')
    data = data[2] + '/' + data[1] + '/' + data[0]
    return data
})

const app = new Vue({
    el: '#app',
    store
});

if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js")
            .then(() => console.log("Service Worker registrado!"))
            .catch((err) => console.log("Erro ao registrar Service Worker:", err));
    });
}
