<template>
  <div class="col-md-6">
    <div class="card">
      <div class="card-header titulo-mudanca" style="text-align: center">
        <b>M U D A R - T I P O - D A - C E S T A</b>
      </div>
      <div class="card-body">
        <div class="row justify-content-center" style="padding: 0;">
          <div class="col">
            <alert-component mensagem="Transação realizada com sucesso" tipo="success"
              :detalhes="$store.state.transacao" v-if="$store.state.transacao.status == 'sucesso'"></alert-component>
            <alert-component mensagem="Erro na transação" tipo="danger" :detalhes="$store.state.transacao"
              v-if="$store.state.transacao.status == 'erro'"></alert-component>
            <p style="font-size: 20px;">Sua opção Atual: "Opção que já está no sistema": {{ tipocesta == 0 ? "A" : "B"
              }}</p>
            <input-container-component titulo="Caso deseja mudar sua opção selecione aqui e clique em salvar.">
              <div class="row">
                <!-- Input tipo radio para opção A -->
                <label>
                  <input type="radio" name="tipo_cesta" value="0" v-model="mtipocesta" />
                  A
                </label>
              </div>
              <div class="row">
                <!-- Input tipo radio para opção B -->
                <label>
                  <input type="radio" name="tipo_cesta" value="1" v-model="mtipocesta" />
                  B
                </label>
              </div>
              <!-- Input tipo radio para opção B -->
              <div class="row">
                <label>
                  <input type="radio" name="tipo_cesta" value="permanece" v-model="permanece" />
                  Não desejo alterar, manter opção Atual
                </label>
              </div>
            </input-container-component>
            <button type="button" class="btn btn-primary m-1 float-end" @click="atualizarTipoCesta()">
              Salvar
            </button>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col">
            <b>CESTA A</b>
            <div class="open-popup-link">
              <a href="https://portalcolaborador.candido.org.br/storage/imagens/principals/cesta_tipo_a.jpg"
                class="img">
                <img src="https://portalcolaborador.candido.org.br/storage/imagens/principals/cesta_tipo_a.jpg"
                  alt="Descrição Listagem da Cesta Tipo A" width="80px" height="200px" />
              </a>
            </div>
          </div>
          <div class="col">
            <b>CESTA B</b>
            <div class="open-popup-link">
              <a href="https://portalcolaborador.candido.org.br/storage/imagens/principals/cesta_tipo_b.jpg"
                class="img">
                <img src="https://portalcolaborador.candido.org.br/storage/imagens/principals/cesta_tipo_b.jpg"
                  alt="Descrição Listagem da Cesta Tipo B" width="80px" height="200px" />
              </a>
            </div>
          </div>
          <div>
            <p>Lista de itens da Cesta Premium, bônus para colaboradores que não possuam ausência no período.</p>
            <div class="open-popup-link" style="width: 60%">
              <a href="https://portalcolaborador.candido.org.br/storage/imagens/principals/lista_cesta_premium.jpg"
                class="img">
                <img src="https://portalcolaborador.candido.org.br/storage/imagens/principals/lista_cesta_premium.jpg"
                  alt="Descrição lista Cesta Premium" width="100px" height="200px" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["tipocesta", "iduser"],
  data() {
    return {
      mtipocesta: '',
      permanece: ''
    }
  },
  methods: {
    atualizarTipoCesta() {
      if (this.permanece == 'permanece') {
        let msg = this.tipocesta == '0' ? 'A' : 'B'
        let confirmacao = confirm("A cesta continua a mesma, CESTA: "+msg );
        this.permanece = ''
        return
      }
      let confirmacao = confirm("Confirmar mudança do tipo da cesta?");
      if (!confirmacao) return false;
      let formData = new FormData();
      formData.append("_method", "patch");
      formData.append("tipo_cesta", this.mtipocesta);

      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json",
          Authorization: this.token,
        },
      };

      this.tipocesta = this.mtipocesta
      axios
        .post("https://portalcolaborador.candido.org.br/api/v1/usuarios/" + this.iduser, formData, config)
        .then((response) => {
          this.setMessage("sucesso", response.data.msg);
          this.carregarPrincipal();
        })
        .catch((errors) => {
          this.setMessage(
            "erro",
            errors.response.data.message,
            errors.response.data.errors
          );
        });
    }
  },
  mounted() {
    $(".open-popup-link").magnificPopup({
      delegate: "a",
      type: "image",
    });
  },
  computed: {

  }
};
</script>
<style>
@media screen and (max-width: 450px) {
  .titulo-mudanca {
    font-size: 10px;
  }
}
</style>
